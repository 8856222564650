import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Cancel } from "../assets/ico_cancel.svg";

const DateSelector = (
  theme,
  setShowDateSelector,
  startDate,
  setStartDate,
  endDate,
  setEndDate
) => {
  const handleChangeStartDate = (date) => {
    setStartDate(date);
    if (endDate && endDate < date) {
      setEndDate(date);
    }
  };

  const dateSelector = () => {
    return (
      <>
        {setShowDateSelector && (
          <div>
            <button
              className="outline_icon hoverable"
              onClick={() => {
                setShowDateSelector(false);
              }}
              style={{ float: "right", right: 30, top: 30 }}
            >
              <Cancel className="fill_text_primary" style={{ scale: ".8" }} />
            </button>
          </div>
        )}
        <div>
          <label style={{ marginTop: -10 }} htmlFor="start_date">
            Select start date
          </label>
          <DatePicker
            id="start_date"
            className="modal_date_picker_input"
            placeholderText="Select start date"
            selected={startDate}
            onChange={(date) => handleChangeStartDate(date)}
          />
          <label htmlFor="end_date">Select end date</label>
          <DatePicker
            id="end_date"
            className="modal_date_picker_input"
            placeholderText="Select end date"
            minDate={startDate}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
        {setShowDateSelector && (
          <button
            className="hoverable bg_primary_button"
            style={{
              color: theme.alert_color,
              margin: "20px auto",
              fontWeight: 600,
            }}
            onClick={() => {
              setStartDate("");
              setEndDate("");
            }}
          >
            Reset
          </button>
        )}
      </>
    );
  };

  return { handleChangeStartDate, dateSelector };
};

export default DateSelector;
