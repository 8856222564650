import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  displayDollarAmount,
  monthDateYear,
  unCapitalize,
} from "../../utils/utils";
import useAPI from "../../api/api";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { ReactComponent as ArrowLeft } from "../../assets/ico_arrow_left.svg";
import { ReactComponent as Cancel } from "../../assets/ico_cancel.svg";
import DataSorting from "../../components/dataSorting";
import DataLoadingAndErrorHandling from "../../components/dataLoadingAndErrorHandling";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const DisbursementDetail = ({
  theme,
  sortDisbursementDetailConfig,
  setSortDisbursementDetailConfig,
  highlightToDos,
}) => {
  const api = useAPI();
  const urlParams = useParams();
  const navigate = useNavigate();
  const { MONITOR } = ROUTE_CONSTANTS;
  const [showPlan, setShowPlan] = useState(false);
  const [transferToCancel, setTransferToCancel] = useState(undefined);
  // const [transferToResend, setTransferToResend] = useState(undefined);
  const [disbursementPlan, setDisbursementPlan] = useState(undefined);
  const [disbursementPayeePlans, setDisbursementPayeePlans] = useState([]);
  const [disbursementPayeePlanStatuses, setDisbursementPayeePlanStatuses] =
    useState([]);
  const [modalResponse, setModalResponse] = useState(null);

  useEffect(() => {
    highlightToDos();
  }, [showPlan, highlightToDos]);

  const { sortableHeader, sortData } = DataSorting(
    sortDisbursementDetailConfig,
    setSortDisbursementDetailConfig
  );

  const { renderErrorOrLoadingMessage, setFetchLoading, setFetchError } =
    DataLoadingAndErrorHandling();

  const getDisbursementAndPayeePlans = useCallback(async () => {
    try {
      setFetchLoading(true);
      const response = await api.get(
        `/disbursement_plan/${urlParams.idString}`
      );
      setDisbursementPlan(response.data.disbursementPlan);
      setDisbursementPayeePlans(response.data.disbursementPlan.payeePlans);
    } catch (error) {
      setFetchError(true);
      console.error("get disbursement plan error", error);
    } finally {
      setFetchLoading(false);
    }
  }, [api, urlParams, setFetchLoading, setFetchError]);

  const getPlanStatuses = useCallback(async () => {
    try {
      setFetchLoading(true);
      const response = await api.get(`/payments/${urlParams.idString}`);
      setDisbursementPayeePlanStatuses(response.data.payments);
    } catch (error) {
      setFetchError(true);
      console.error("get disbursement plan error", error);
    } finally {
      setFetchLoading(false);
    }
  }, [api, urlParams, setFetchLoading, setFetchError]);

  useEffect(() => {
    getDisbursementAndPayeePlans();
    getPlanStatuses();
  }, [getDisbursementAndPayeePlans, getPlanStatuses]);

  disbursementPayeePlanStatuses?.length > 0
    ? disbursementPayeePlans.forEach((payeePlan) => {
        const match = disbursementPayeePlanStatuses.find(
          ({ payee_identifier }) => payee_identifier === payeePlan.payeeEmail
        );
        payeePlan.planStatus = match ? match.payment_status : "";
        payeePlan.payment_id = match ? match.payment_id : "";
      })
    : disbursementPayeePlans.forEach((payeePlan) => {
        payeePlan.planStatus = "Scheduled";
        payeePlan.payment_id = "";
      });

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const csvExport = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + ".csv");
  };

  const exportPayeePlans = disbursementPayeePlans.map((plan) => {
    if (disbursementPayeePlanStatuses?.length > 0) {
      return {
        payment_id: plan.payment_id,
        payee: plan.payeeName,
        status: plan.planStatus,
        email: plan.payeeEmail,
        amount: plan.amount && displayDollarAmount(plan.amount),
      };
    } else
      return {
        payee: plan.payeeName,
        status: plan.planStatus,
        email: plan.payeeEmail,
        amount: plan.amount && displayDollarAmount(plan.amount),
      };
  });

  const renderPlansTable = () => {
    sortData(disbursementPayeePlans);

    return (
      <table>
        <thead
          style={{
            height: 42,
          }}
        >
          <tr style={{ height: 64 }}>
            <td colSpan={6} style={{ fontSize: 18, fontWeight: 550 }}>
              Payments
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              backgroundColor: theme.bg_tertiary,
              fontSize: 12,
              height: 42,
            }}
          >
            {sortableHeader("payeeName", "Name")}
            {sortableHeader("planStatus", "Payment Status")}
            {sortableHeader("payeeEmail", "Email Address")}
            {sortableHeader("amount", "Amount ($)")}
            {/* <td>Group</td> */}
            <td style={{ textAlign: "center" }} colSpan={2}>
              Actions
            </td>
          </tr>
          {disbursementPayeePlans.length > 0 &&
            disbursementPayeePlans.map((plan, i) => {
              const rowCells = document.getElementsByClassName(
                `row_cells_${i}`
              );
              const addRowHighlight = () => {
                for (let i = 0; i < rowCells.length; i++) {
                  rowCells[i].style.backgroundColor = theme.bg_secondary;
                  rowCells[i].style.cursor = "pointer";
                }
              };

              const removeRowHighlight = () => {
                for (let i = 0; i < rowCells.length; i++) {
                  rowCells[i].style.backgroundColor = theme.bg_primary;
                }
              };

              const tableCell = (contents, cellStyle) => {
                return (
                  <td
                    className={`row_cells_${i}`}
                    onMouseOver={() => addRowHighlight(i)}
                    onMouseLeave={() => removeRowHighlight(i)}
                    onClick={() => setShowPlan(plan)}
                    style={cellStyle}
                  >
                    {contents}
                  </td>
                );
              };

              return (
                <tr key={i} className="border_top ">
                  {tableCell(plan.payeeName, null)}
                  {tableCell(plan.planStatus, null)}
                  {tableCell(
                    plan.payeeEmail?.length > 40
                      ? `${plan.payeeEmail.slice(0, 25)}...`
                      : plan.payeeEmail,
                    null
                  )}
                  {tableCell(plan.amount && displayDollarAmount(plan.amount), {
                    textAlign: "right",
                  })}
                  {plan.planStatus === "Notified Recipient" ? (
                    <>
                      <td
                        className="hoverable"
                        onClick={() => setTransferToCancel(plan)}
                        style={{
                          color: theme.alert_color,
                          fontWeight: 600,
                          textAlign: "center",
                        }}
                      >
                        Cancel
                      </td>
                      {/* <td
                        className="hoverable"
                        onClick={() => setTransferToResend(plan)}
                        style={{
                          color: theme.accent_color,
                          fontWeight: 600,
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Resend Email
                      </td> */}
                    </>
                  ) : (
                    <>
                      <td></td>
                      {/* <td></td> */}
                    </>
                  )}
                </tr>
              );
            })}
          {/* remove below for v0 */}
          {/* <tr className="border_top">
            <td colSpan={3}>
              <span>TODO </span>
              <span>pagination</span>
            </td>
            <td colSpan={3} style={{ fontWeight: 900 }}>
              <div style={{ display: "flex", float: "right" }}>
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    borderColor: theme.text_secondary,
                    fontWeight: 550,
                    marginRight: 10,
                  }}
                  onClick={() => console.log("Previous")}
                >
                  Previous
                </button>
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    borderColor: theme.text_secondary,
                    fontWeight: 550,
                  }}
                  onClick={() => console.log("Next")}
                >
                  Next
                </button>
              </div>
            </td>
          </tr> */}
        </tbody>
      </table>
    );
  };

  const renderPlanDetail = () => {
    return (
      <>
        <button
          className="outline_icon hoverable"
          onClick={() => setShowPlan(false)}
        >
          <ArrowLeft className="stroke_text_primary" style={{ scale: ".8" }} />
        </button>
        <h2>Payment Plan Detail</h2>
        <hr></hr>
        <div className="detail_container">
          <div>
            <div className="detail_data">Recipient Name</div>
            <>{showPlan.payeeName}</>
          </div>
          <div>
            <div className="detail_data">Recipient Email</div>
            <>{showPlan.payeeEmail}</>
          </div>
          {/* remove below for v0 */}
          {/* <div>
            <div className="detail_data">Recipient Group</div>
            <span>TODO</span>
          </div> */}
          <div>
            <div className="detail_data">Recipient Status</div>
            <>{unCapitalize(showPlan.payeeStatus)}</>
          </div>
          <div>
            <div className="detail_data">Payment Amount</div>
            <>{showPlan.amount && displayDollarAmount(showPlan.amount)}</>
          </div>
          <div>
            <div className="detail_data">Payment Status</div>
            <>{showPlan.planStatus}</>
          </div>
          {showPlan.payment_id && (
            <div>
              <div className="detail_data">Payment ID</div>
              <>{showPlan.payment_id}</>
            </div>
          )}
        </div>
      </>
    );
  };

  const actionModal = (actionableTransfer, setActionableTransfer, isCancel) => {
    const cancelTransfer = async () => {
      try {
        await api.delete(
          `/transfer/${transferToCancel.payment_id}/${urlParams.idString}`
        );
        setTimeout(() => {
          getPlanStatuses();
        }, 2000);
        setActionableTransfer(undefined);
      } catch (error) {
        setModalResponse(
          "An error occurred, the transfer was not cancelled. Please contact support for assistance."
        );
        console.error("cancel error", error);
      }
    };

    // const resendEmail = async () => {
    //   try {
    //     await api.post(`/transfer/resend/${transferToResend.payment_id}`);
    //     setTimeout(() => {
    //       getPlanStatuses();
    //     }, 2000);
    //     setActionableTransfer(undefined);
    //   } catch (error) {
    //     setModalResponse(
    //       "An error occurred, the email was not resent. Please contact support for assistance."
    //     );
    //     console.error("resend email error", error);
    //   }
    // };

    return (
      <>
        <div className="overlay"></div>
        <div className="modal vertical_space_between">
          <div>
            <button
              className="outline_icon hoverable"
              onClick={() => {
                setActionableTransfer(undefined);
                setModalResponse(null);
              }}
              style={{ float: "right", right: 30, top: 30 }}
            >
              <Cancel className="fill_text_primary" style={{ scale: ".8" }} />
            </button>
          </div>
          {modalResponse ? (
            <>
              <h3>{modalResponse}</h3>
              <div style={{ height: 80 }}></div>
            </>
          ) : (
            <>
              <h3>
                Are you sure you want to
                {isCancel ? (
                  <> cancel the transfer </>
                ) : (
                  <> resend the email </>
                )}
                from disbursement
                <strong> {actionableTransfer.disbursementPlanName} </strong> for
                <strong> ${actionableTransfer.amount} </strong> to
                <strong> {actionableTransfer.payeeName}</strong>?
              </h3>
              <button
                className="hoverable bg_primary_button"
                style={{
                  color: isCancel ? theme.alert_color : theme.accent_color,
                  margin: "20px auto",
                  fontWeight: 600,
                }}
                onClick={cancelTransfer}
                // onClick={isCancel ? cancelTransfer : resendEmail}
              >
                Confirm
                {isCancel ? <> Cancel Transfer</> : <> Resend Email</>}
              </button>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="page_no_sidebar">
      <>
        {transferToCancel &&
          actionModal(transferToCancel, setTransferToCancel, true)}
        {/* {transferToResend &&
          actionModal(transferToResend, setTransferToResend, false)} */}
        {showPlan ? (
          <>{renderPlanDetail()}</>
        ) : (
          <>
            <button
              className="outline_icon hoverable"
              onClick={() => navigate(MONITOR)}
            >
              <ArrowLeft
                className="stroke_text_primary"
                style={{ scale: ".8" }}
              />
            </button>
            {disbursementPlan ? (
              <>
                <h2>{disbursementPlan.name}</h2>
                <div className="tile_container" style={{ marginTop: 20 }}>
                  <div className="tile">
                    <>Total Amount</>
                    <br></br>
                    {/* <span>TODO </span>
                    <span>Should both of these numbers come from backend?</span> */}
                    <h2>
                      {/* remove below for v0 */}
                      {/* {disbursementPayeePlans.length > 0 && (
                        <>
                          {displayDollarAmount(
                            disbursementPayeePlans
                              .filter(
                                ({ planStatus }) => planStatus === "Succeeded"
                              )
                              .reduce((runningTotal, payee) => {
                                if (payee.amount) {
                                  return (
                                    runningTotal + parseFloat(payee.amount)
                                  );
                                } else {
                                  return runningTotal;
                                }
                              }, 0)
                          ).slice(0, -3)}{" "}
                          of{" "}
                        </>
                      )} */}
                      {disbursementPlan?.calculatedTotal &&
                        displayDollarAmount(
                          disbursementPlan.calculatedTotal
                        ).slice(0, -3)}
                    </h2>
                  </div>
                  <div className="tile">
                    <>Total Payees</>
                    <br></br>
                    {/* <span>TODO </span>
                    <span>Should both of these numbers come from backend?</span> */}
                    <h2>
                      {/* remove below for v0 */}
                      {/* {
                        disbursementPayeePlans.filter(
                          ({ planStatus }) => planStatus === "Succeeded"
                        ).length
                      }{" "}
                      of  */}
                      {disbursementPlan.numPayees}
                    </h2>
                  </div>
                  <div className="tile">
                    <h3
                      style={{
                        display: "flex",
                        height: "100%",
                        margin: 0,
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div style={{ marginBottom: 10 }}>Creation Date:</div>
                        <div>Send Date:</div>
                        {/* remove below for v0 */}
                        {/* <div>Expiry Date:</div> */}
                      </div>
                      <div style={{ textAlign: "end" }}>
                        <div style={{ marginBottom: 10 }}>
                          {disbursementPlan?.createdAtLocalTimezone &&
                            monthDateYear(
                              new Date(
                                `${disbursementPlan?.createdAtLocalTimezone?.split("T")[0]}T00:00:00`
                              )
                            )}
                        </div>
                        <div style={{ height: 20 }}>
                          {disbursementPlan?.startDate &&
                            monthDateYear(
                              new Date(
                                `${disbursementPlan?.startDate?.split("T")[0]}T00:00:00`
                              )
                            )}
                        </div>
                        {/* remove below for v0 */}
                        {/* <div style={{ height: 20 }}>
                          {disbursementPlan?.endDate &&
                            monthDateYear(
                              new Date(
                                `${disbursementPlan?.endDate?.split("T")[0]}T00:00:00`
                              )
                            )}
                        </div> */}
                      </div>
                    </h3>
                  </div>
                  <div className="tile">
                    <>Created By</>
                    <h2>{disbursementPlan.createdByUserName}</h2>
                  </div>
                  {/* remove below for v0 */}
                  {/* <div className="tile">
                    Plan Status
                    <h2>
                      {disbursementPlan.status === "IN_PROCESS" ? (
                        <>Processing</>
                      ) : (
                        <>
                          {disbursementPlan.status === "APPROVED" ? (
                            <>Approved</>
                          ) : (
                            <>Draft</>
                          )}
                        </>
                      )}
                    </h2>
                  </div> */}
                </div>
                {disbursementPayeePlans.length > 0 ? (
                  <>
                    <button
                      style={{
                        backgroundColor: theme.accent_color,
                        borderColor: theme.accent_color,
                        color: theme.bg_tertiary,
                        float: "right",
                        marginBottom: 20,
                      }}
                      onClick={() =>
                        csvExport(
                          exportPayeePlans,
                          `${disbursementPlan.name}_plans`
                        )
                      }
                    >
                      Export CSV
                    </button>
                    {renderPlansTable()}
                  </>
                ) : (
                  <>
                    There was an issue fetching the payee data associated with
                    this disbursement.
                  </>
                )}
              </>
            ) : (
              <>{renderErrorOrLoadingMessage("Disbursement detail")}</>
            )}
          </>
        )}
      </>
    </div>
  );
};
export default DisbursementDetail;
