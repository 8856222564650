import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/ico_arrow_left.svg";
import useAPI from "../../api/api";
import {
  displayDollarAmount,
  monthDateYear,
  unCapitalize,
} from "../../utils/utils";
import DataSorting from "../../components/dataSorting";
import DataLoadingAndErrorHandling from "../../components/dataLoadingAndErrorHandling";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const PayeesScreen = ({
  theme,
  sortPayeesConfig,
  setSortPayeesConfig,
  sortPayeePlansConfig,
  setSortPayeePlansConfig,
  filterPayeePlans,
  setFilterPayeePlans,
  highlightToDos,
}) => {
  const api = useAPI();
  const [payees, setPayees] = useState([]);
  const [payeePlans, setPayeePlans] = useState([]);
  const [payeePlansToRender, setPayeePlansToRender] = useState([]);
  const [payeePlanStatuses, setPayeePlanStatuses] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [showPayee, setShowPayee] = useState(false);

  const { renderErrorOrLoadingMessage, setFetchLoading, setFetchError } =
    DataLoadingAndErrorHandling();

  const payeeId = showPayee.idString;

  highlightToDos();

  useEffect(() => {
    const getPayees = async () => {
      try {
        setFetchLoading(true);
        const response = await api.get(`/payees`);
        response.data.payees.forEach((payee) => {
          if (payee.status) {
            payee.payeeStatus = unCapitalize(payee.status);
          }
        });
        setPayees(response.data.payees);
      } catch (error) {
        setFetchError(true);
        console.error("get disbursement plans error", error);
      } finally {
        setFetchLoading(false);
      }
    };
    getPayees();
  }, [api, setFetchError, setFetchLoading]);

  useEffect(() => {
    const getPayeePlans = async () => {
      if (showPayee) {
        try {
          setFetchLoading(true);
          const response = await api.get(`/payee_payee_plans/${payeeId}`);
          setPayeePlans(response.data.payeePlans);
          setPayeePlansToRender(response.data.payeePlans);
        } catch (error) {
          setFetchError(true);
          console.error("get disbursement plans error", error);
        } finally {
          setFetchLoading(false);
        }
      }
    };
    getPayeePlans();
  }, [api, showPayee, payeeId, setFetchError, setFetchLoading]);

  useEffect(() => {
    const getPlanStatuses = async () => {
      if (showPayee) {
        try {
          setFetchLoading(true);
          const response = await api.post(`/payee/payments`, {
            email: showPayee.email,
          });
          setPayeePlanStatuses(response.data.payments);
        } catch (error) {
          setFetchError(true);
          console.error("get disbursement plans error", error);
        } finally {
          setFetchLoading(false);
        }
      }
    };
    getPlanStatuses();
  }, [api, showPayee, payeeId, setFetchError, setFetchLoading]);

  payeePlans.forEach((payeePlan) => {
    const match = payeePlanStatuses?.find(
      ({ disbursement_id }) =>
        disbursement_id.slice(0, 15) ===
        payeePlan.disbursementPlanId.toString().slice(0, 15)
    );
    payeePlan.status = match ? match.payment_status : "Scheduled";
    payeePlan.payment_id = match ? match.payment_id : "";
  });

  const renderPayeesTable = () => {
    const { sortableHeader, sortData } = DataSorting(
      sortPayeesConfig,
      setSortPayeesConfig
    );

    sortData(filteredPayees);

    const showPayeeDetails = (payee) => {
      setShowPayee(payee);
      window.scrollTo(0, 0);
    };

    return (
      <table>
        <thead
          style={{
            height: 42,
          }}
        >
          <tr style={{ height: 64 }}>
            <td colSpan={4} style={{ fontSize: 18, fontWeight: 550 }}>
              Payees
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              backgroundColor: theme.bg_tertiary,
              fontSize: 12,
              height: 42,
            }}
          >
            {sortableHeader("name", "Name")}
            {sortableHeader("payeeStatus", "Payee Status")}
            {sortableHeader("email", "Email Address")}
            {/* <td>Group</td> */}
          </tr>
          {filteredPayees.map((payee, i) => {
            return (
              <tr
                key={i}
                className="border_top hoverable"
                onClick={() => showPayeeDetails(payee)}
              >
                <td>{payee.name}</td>
                <td>{payee.payeeStatus}</td>
                <td>
                  {payee.email.length > 40
                    ? `${payee.email.slice(0, 25)}...`
                    : payee.email}
                </td>
                {/* <td>TODO</td> */}
              </tr>
            );
          })}
          {/* remove below for v0 */}
          {/* <tr className="border_top">
            <td colSpan={2}>
              <span>TODO </span>
              <span>pagination</span>
            </td>
            <td colSpan={2} style={{ fontWeight: 900 }}>
              <div style={{ display: "flex", float: "right" }}>
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    borderColor: theme.text_secondary,
                    fontWeight: 550,
                    marginRight: 10,
                  }}
                  onClick={() => console.log("Previous")}
                >
                  Previous
                </button>
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    borderColor: theme.text_secondary,
                    fontWeight: 550,
                  }}
                  onClick={() => console.log("Next")}
                >
                  Next
                </button>
              </div>
            </td>
          </tr> */}
        </tbody>
      </table>
    );
  };

  const setHandleFilterBy = useCallback(
    (type) => {
      setFilterPayeePlans(type);
      switch (type) {
        case "Pending":
          return setPayeePlansToRender(
            payeePlans.filter(
              ({ status }) => status !== "Succeeded" && status !== "Failed"
            )
          );
        case null:
          return setPayeePlansToRender(payeePlans);
        default:
          return setPayeePlansToRender(
            payeePlans.filter(({ status }) => status === type)
          );
      }
    },
    [payeePlans, setFilterPayeePlans]
  );

  useEffect(() => {
    setHandleFilterBy(filterPayeePlans);
  }, [filterPayeePlans, setHandleFilterBy]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const csvExport = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + ".csv");
  };

  const renderPayeeDetail = () => {
    const { sortableHeader, sortData } = DataSorting(
      sortPayeePlansConfig,
      setSortPayeePlansConfig
    );

    sortData(payeePlansToRender);

    const renderFilterBy = (type) => {
      return (
        <div
          className={
            filterPayeePlans === type ? "accent_box filter_by" : "filter_by"
          }
          style={filterPayeePlans === type ? { margin: "-1px 2px" } : {}}
          onClick={() => setHandleFilterBy(type)}
        >
          {type}
          <span
            className="filter_by_box"
            style={
              filterPayeePlans === type
                ? { borderColor: theme.accent_color }
                : {}
            }
          >
            {type === "Pending"
              ? payeePlans.filter(
                  ({ status }) => status !== "Succeeded" && status !== "Failed"
                ).length
              : payeePlans.filter(({ status }) => status === type).length}
          </span>
        </div>
      );
    };

    const goBack = () => {
      setPayeePlans([]);
      setPayeePlansToRender([]);
      setPayeePlanStatuses([]);
      setSearchField("");
      setShowPayee(false);
    };

    const exportPayeePlans = payeePlansToRender.map((plan) => {
      return {
        id: plan.payment_id ? plan.payment_id : "N/A",
        date: plan.disbursementPlanStartDate,
        status: plan.status,
        amount: plan.amount && displayDollarAmount(plan.amount),
        disbursement_plan_name: plan.disbursementPlanName,
      };
    });

    return (
      <>
        <button className="outline_icon hoverable" onClick={() => goBack()}>
          <ArrowLeft className="stroke_text_primary" style={{ scale: ".8" }} />
        </button>
        <h2>Payee Detail</h2>
        <hr></hr>
        <div className="detail_container">
          <div>
            <div className="detail_data">Name</div>
            <div>{showPayee.name}</div>
          </div>
          <div>
            <div className="detail_data">Email</div>
            <div>{showPayee.email}</div>
          </div>
          <div>
            <div className="detail_data">Payee Status</div>
            <div>{showPayee.payeeStatus}</div>
          </div>
          {/* remove below for v0 */}
          {/* <div>
            <div className="detail_data">Group</div>
            <div>TODO</div>
          </div> */}
          <div>
            <div className="detail_data">Payee ID</div>
            <div>{showPayee.idString}</div>
          </div>
        </div>
        {payeePlans.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                margin: "40px 0 20px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className={
                    !filterPayeePlans ? "accent_box filter_by" : "filter_by"
                  }
                  style={!filterPayeePlans ? { margin: "-1px 2px" } : {}}
                  onClick={() => setHandleFilterBy(null)}
                >
                  All
                  <span
                    className="filter_by_box"
                    style={
                      !filterPayeePlans
                        ? { borderColor: theme.accent_color }
                        : {}
                    }
                  >
                    {payeePlans.length}
                  </span>
                </div>
                {renderFilterBy("Succeeded")}
                {renderFilterBy("Pending")}
                {renderFilterBy("Failed")}
              </div>
              {exportPayeePlans.length > 0 && (
                <button
                  style={{
                    backgroundColor: theme.accent_color,
                    borderColor: theme.accent_color,
                    color: theme.bg_tertiary,
                  }}
                  onClick={() =>
                    csvExport(
                      exportPayeePlans,
                      `${showPayee.name}_${filterPayeePlans ? filterPayeePlans.toLowerCase() : "all"}_plans`
                    )
                  }
                >
                  Export CSV
                </button>
              )}
            </div>
            {payeePlansToRender.length > 0 ? (
              <table>
                <thead
                  style={{
                    height: 42,
                  }}
                >
                  <tr style={{ height: 64 }}>
                    <td colSpan={5} style={{ fontSize: 18, fontWeight: 550 }}>
                      Disbursement History
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      backgroundColor: theme.bg_tertiary,
                      fontSize: 12,
                      height: 42,
                    }}
                  >
                    {sortableHeader("disbursementPlanStartDate", "Date")}
                    {sortableHeader("status", "Status")}
                    {sortableHeader("amount", "Amount ($)")}
                    {sortableHeader(
                      "disbursementPlanName",
                      "Disbursement Name"
                    )}
                    <td>Actions</td>
                  </tr>
                  {payeePlansToRender.map((payeePlan, i) => {
                    return (
                      <tr key={i} className="border_top">
                        <td>
                          {payeePlan.disbursementPlanStartDate &&
                            monthDateYear(
                              new Date(
                                `${payeePlan.disbursementPlanStartDate?.split("T")[0]}T00:00:00`
                              )
                            )}
                        </td>
                        <td>{payeePlan.status}</td>
                        <td style={{ textAlign: "right" }}>
                          {payeePlan.amount &&
                            displayDollarAmount(payeePlan.amount)}
                        </td>
                        <td>{payeePlan.disbursementPlanName}</td>
                        <td
                          className={
                            payeePlan.status === "Failed" ||
                            payeePlan.status === "Pending"
                              ? "hoverable"
                              : ""
                          }
                          style={{
                            whiteSpace: "nowrap",
                            fontWeight: 600,
                          }}
                        >
                          {/* {payeePlan.status === "Failed" ? (
                            <span style={{ color: theme.accent_color }}>
                              Resend Email
                            </span>
                          ) : (
                            <>
                              {payeePlan.status === "Pending" && (
                                <span style={{ color: theme.alert_color }}>
                                  Cancel
                                </span>
                              )}
                            </>
                          )} */}
                        </td>
                      </tr>
                    );
                  })}
                  {/* remove below for v0 */}
                  {/* <tr className="border_top">
                    <td colSpan={2}>
                      <span>TODO </span>
                      <span>pagination</span>
                    </td>
                    <td colSpan={3} style={{ fontWeight: 900 }}>
                      <div style={{ display: "flex", float: "right" }}>
                        <button
                          style={{
                            backgroundColor: theme.bg_primary,
                            borderColor: theme.text_secondary,
                            fontWeight: 550,
                            marginRight: 10,
                          }}
                          onClick={() => console.log("Previous")}
                        >
                          Previous
                        </button>
                        <button
                          style={{
                            backgroundColor: theme.bg_primary,
                            borderColor: theme.text_secondary,
                            fontWeight: 550,
                          }}
                          onClick={() => console.log("Next")}
                        >
                          Next
                        </button>
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            ) : (
              <>
                There are no {filterPayeePlans.toLowerCase()} payee plans to
                show.
              </>
            )}
          </>
        ) : (
          <>{renderErrorOrLoadingMessage("Payee plans")}</>
        )}
      </>
    );
  };

  const filteredPayees = payees.filter(
    (each) =>
      each.name.toLowerCase().includes(searchField) ||
      each.email.toLowerCase().includes(searchField)
  );

  return (
    <div className="page">
      {showPayee ? (
        <>{renderPayeeDetail()}</>
      ) : (
        <>
          <h2>Payees</h2>
          <div
            style={{
              marginTop: 10,
              fontSize: 16,
              fontWeight: 300,
            }}
          >
            Manage your payees
          </div>
          <hr></hr>
          {payees.length > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  marginBottom: 20,
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{ width: "25%", height: 34 }}
                  onChange={({ target: { value } }) => {
                    setSearchField(value.toLowerCase());
                  }}
                  placeholder="Search"
                />
              </div>
              {filteredPayees.length > 0 ? (
                <> {renderPayeesTable(payees)}</>
              ) : (
                <>There are no payees that match the search.</>
              )}
            </>
          ) : (
            <>{renderErrorOrLoadingMessage("Payees")}</>
          )}
        </>
      )}
    </div>
  );
};
export default PayeesScreen;
