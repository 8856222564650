const Theme = () => {
  // get rootColors from stylesheet

  const rootColors = [
    "text_primary",
    "text_secondary",
    "bg_primary",
    "bg_secondary",
    "bg_tertiary",
    "accent_color",
    "success_color",
    "info_color",
    "alert_color",
    "accent_color_light",
    "success_color_light",
    "info_color_light",
    "alert_color_light",
  ];

  const theme = {};

  for (let i = 0; i < rootColors.length; i++) {
    theme[rootColors[i]] = getComputedStyle(document.body).getPropertyValue(
      `--${rootColors[i]}`
    );
  }

  const dropDownStyle = {
    control: (base) => ({
      ...base,
      backgroundColor: theme.bg_primary,
      height: 44,
      borderColor: theme.text_secondary,
      padding: "0 6px",
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: 8,
      backgroundColor: theme.bg_primary,
    }),
    option: (base) => ({
      ...base,
      padding: 15,
      "&:hover": {
        backgroundColor: theme.bg_tertiary,
        // color: theme.bg_primary,
        fontWeight: 600,
        cursor: "pointer",
      },
    }),
    multiValue: (base) => ({
      ...base,
      border: "solid",
      borderWidth: 1,
      borderColor: theme.text_secondary,
    }),
    // multiValueLabel: (base) => ({
    //   ...base,
    //   color: theme.alert_color,
    // }),
    clearIndicator: (base) => ({
      ...base,
      color: theme.text_primary,
      ":hover": {
        backgroundColor: theme.bg_primary,
        color: theme.alert_color,
        transform: "scale(1.15)",
        cursor: "pointer",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      ":hover": {
        transform: "scale(1.15)",
        cursor: "pointer",
      },
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: theme.text_secondary,
      ":hover": {
        backgroundColor: theme.alert_color,
        color: theme.bg_primary,
        cursor: "pointer",
      },
    }),
  };

  const dropDownTheme = (base) => ({
    ...base,
    rootColors: {
      primary: theme.bg_tertiary,
    },
    borderRadius: 8,
  });

  return { theme, dropDownStyle, dropDownTheme };
};

export default Theme;
