import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Settings = ({ theme }) => {
  const { user } = useAuth0();
  //   const [searchField, setSearchField] = useState("");

  const detailRow = (title, detail) => {
    return (
      <>
        <hr></hr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: "25%", marginTop: -20 }}>{title}</div>
          <div style={{ width: "50%" }}>{detail}</div>
          <div style={{ width: "25%" }}></div>
        </div>
      </>
    );
  };

  return (
    <div className="page">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <h2>Settings</h2>
        {/* hide for v0 */}
        {/* <input
          style={{ width: "25%", height: 34, marginTop: 10 }}
          onChange={({ target: { value } }) => {
            setSearchField(value.toLowerCase());
          }}
          placeholder="Search"
        /> */}
      </div>
      <hr></hr>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            //   justifyContent: "space-between",
            //   alignItems: "center",
          }}
        >
          <h3 style={{ marginTop: 5 }}>My details</h3>
          <div style={{ color: theme.text_secondary }}>
            Update your photo and personal details here.
          </div>
        </div>
        <div style={{ display: "flex", height: "fit-content", gap: 10 }}>
          <button
            style={{
              backgroundColor: theme.bg_primary,
            }}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: theme.accent_color,
              borderColor: theme.accent_color,
              color: theme.bg_tertiary,
            }}
          >
            Save
          </button>
        </div>
      </div>
      {detailRow("Name", <input value={user.name} disabled={true} />)}
      {detailRow("Email address", <input value={user.email} disabled={true} />)}
      {detailRow(
        "Your photo",
        <img
          src={user.picture}
          alt={user.name}
          style={{ height: 60, borderRadius: 30 }}
        />
      )}
      {user.app_metadata?.title &&
        detailRow(
          "Job title",
          <input value={user.app_metadata.title} disabled={true} />
        )}
      {detailRow("Access", <input value="Administrator" disabled={true} />)}
    </div>
  );
};
export default Settings;
