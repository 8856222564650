import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { ReactComponent as CheckMark } from "../../assets/ico_check.svg";
import { ReactComponent as Clipboard } from "../../assets/ico_clipboard.svg";
import { ReactComponent as Result } from "../../assets/ico_result.svg";
import { ReactComponent as Upload } from "../../assets/ico_upload.svg";
import { ReactComponent as Cancel } from "../../assets/ico_cancel.svg";

const ProgressHeader = (theme, step) => {
  const { DASHBOARD } = ROUTE_CONSTANTS;
  const navigate = useNavigate();
  const progressBar = (index) => {
    return (
      <div
        className="progress_bar"
        style={
          step < index
            ? {
                color: theme.text_secondary,
              }
            : {}
        }
      ></div>
    );
  };

  const progressItem = (index, icon, title, detail) => {
    return (
      <div
        className={
          step < index
            ? "progress_item todo"
            : step === index
              ? "progress_item current"
              : "progress_item done"
        }
      >
        <div className="outline_icon" style={{ scale: 1.25, padding: 5 }}>
          {icon}
        </div>
        <b>{title}</b>
        <div style={{ fontWeight: 300, marginTop: 5 }}>{detail}</div>
      </div>
    );
  };

  return (
    <>
      {step < 5 && (
        <div
          style={{
            width: "100%",
            backgroundColor: theme.bg_primary,
            borderBottom: "solid",
            borderColor: theme.text_secondary,
            alignContent: "center",
            padding: "30px 0",
          }}
        >
          <button
            className="outline_icon hoverable"
            onClick={() => navigate(DASHBOARD)}
            style={{ position: "absolute", right: 30 }}
          >
            <Cancel className="fill_text_primary" style={{ scale: ".8" }} />
          </button>
          <div
            style={{
              display: "flex",
              width: "68%",
              margin: "25px auto -25px",
            }}
          >
            {progressBar(2)}
            {progressBar(3)}
            {progressBar(4)}
          </div>
          <div
            style={{
              display: "flex",
              width: "90%",
              margin: "auto",
            }}
          >
            {progressItem(
              1,
              <Clipboard />,
              "General Info",
              "Provide general info for your new payment."
            )}
            {progressItem(
              2,
              <Upload />,
              "Upload file",
              "Upload file to this project."
            )}
            {progressItem(
              3,
              <CheckMark />,
              "Verify Data",
              "Verify that columns are correctly interpreted."
            )}
            {progressItem(
              4,
              <Result />,
              "Result",
              "Check the preview of the created data table."
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressHeader;
