import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { ReactComponent as BarChart } from "../assets/ico_bar_chart.svg";
import { ReactComponent as Page } from "../assets/ico_page.svg";
import { ReactComponent as People } from "../assets/ico_people.svg";
import { ReactComponent as Monitor } from "../assets/ico_monitor.svg";
import { ReactComponent as PieChart } from "../assets/ico_pie_chart.svg";
import { ReactComponent as Settings } from "../assets/ico_settings.svg";
import { ReactComponent as Logout } from "../assets/ico_logout.svg";
import { useAuth0 } from "@auth0/auth0-react";

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout, user } = useAuth0();

  return (
    <>
      {(() => {
        const {
          DASHBOARD,
          DISBURSEMENTS_FORM,
          MONITOR,
          PAYEES,
          REPORTS,
          SETTINGS,
        } = ROUTE_CONSTANTS;
        const path = window.location.pathname;
        const pathOrTrailingSlash = (route) => {
          return path === route || path === route.concat("/");
        };
        const navLink = (icon, title, link_path) => {
          return (
            <div
              className={
                path === link_path
                  ? "nav_link hoverable selected"
                  : "nav_link hoverable"
              }
              onClick={() => navigate(link_path)}
            >
              {icon}
              <div>{title}</div>
            </div>
          );
        };
        switch (true) {
          case pathOrTrailingSlash(DASHBOARD):
          case pathOrTrailingSlash(MONITOR):
          case pathOrTrailingSlash(PAYEES):
          case pathOrTrailingSlash(REPORTS):
          case pathOrTrailingSlash(SETTINGS):
            return (
              <div className="sidebar">
                <div style={{ padding: 20 }}>
                  {user && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={user.picture}
                          alt={user.name}
                          style={{ height: 60, borderRadius: 30 }}
                        />
                      </div>
                      <p style={{ wordWrap: "break-word" }}>{user.name}</p>
                    </>
                  )}
                  {navLink(
                    <BarChart
                      className={
                        path === DASHBOARD ? "selected nav_icon" : "nav_icon"
                      }
                    />,
                    "Dashboard",
                    DASHBOARD
                  )}
                  {navLink(
                    <Page
                      className={
                        path === DISBURSEMENTS_FORM
                          ? "selected nav_icon"
                          : "nav_icon"
                      }
                    />,
                    "Make Payment",
                    DISBURSEMENTS_FORM
                  )}
                  {navLink(
                    <People
                      className={
                        path === PAYEES
                          ? "selected nav_icon fill_accent_color"
                          : "nav_icon fill_text_primary "
                      }
                    />,
                    "Payees",
                    PAYEES
                  )}
                  {navLink(
                    <Monitor
                      className={
                        path === MONITOR ? "selected nav_icon" : "nav_icon"
                      }
                    />,
                    "Monitor",
                    MONITOR
                  )}
                  {navLink(
                    <PieChart
                      className={
                        path === REPORTS ? "selected nav_icon" : "nav_icon"
                      }
                    />,
                    "Reports",
                    REPORTS
                  )}
                  {navLink(
                    <Settings
                      className={
                        path === SETTINGS
                          ? "selected nav_icon fill_accent_color"
                          : "nav_icon fill_text_primary"
                      }
                    />,
                    "Settings",
                    SETTINGS
                  )}
                  <div
                    className="nav_link hoverable"
                    onClick={() =>
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    }
                  >
                    <Logout className="nav_icon" style={{ scale: "1.1" }} />
                    <div>Logout</div>
                  </div>
                </div>
              </div>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default Sidebar;
