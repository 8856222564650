import React, { useState, useEffect } from "react";
import { displayDollarAmount, unCapitalize } from "../../utils/utils";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import useAPI from "../../api/api";
import DataSorting from "../../components/dataSorting";
import DataLoadingAndErrorHandling from "../../components/dataLoadingAndErrorHandling";
import DateSelector from "../../components/dateSelector";

const Reports = ({
  theme,
  sortReportPlansConfig,
  setSortReportPlansConfig,
  reportsStartDate,
  setReportsStartDate,
  reportsEndDate,
  setReportsEndDate,
}) => {
  const api = useAPI();
  const allPlans = [];
  const [disbursementPlans, setDisbursementPlans] = useState([]);

  const { sortableHeader, sortData } = DataSorting(
    sortReportPlansConfig,
    setSortReportPlansConfig
  );

  const { dateSelector } = DateSelector(
    theme,
    null,
    reportsStartDate,
    setReportsStartDate,
    reportsEndDate,
    setReportsEndDate
  );

  const { renderErrorOrLoadingMessage, setFetchLoading, setFetchError } =
    DataLoadingAndErrorHandling();

  useEffect(() => {
    const getDisbursementPlans = async () => {
      try {
        setFetchLoading(true);
        const response = await api.get(`/disbursement_plans`);
        response.data.disbursementPlans.forEach(({ payeePlans }) => {
          payeePlans.forEach((plan) => {
            if (plan.status) {
              plan.status = unCapitalize(plan.status);
            }
          });
        });
        setDisbursementPlans(response.data.disbursementPlans);
      } catch (error) {
        setFetchError(true);
        console.error("get disbursement plans error", error);
      } finally {
        setFetchLoading(false);
      }
    };
    getDisbursementPlans();
  }, [api, setFetchLoading, setFetchError]);

  disbursementPlans.forEach((disbursementPlan) =>
    disbursementPlan.payeePlans.forEach((payeePlan) => {
      payeePlan.disbursementPlanId = disbursementPlan.idString;
      allPlans.push(payeePlan);
    })
  );

  const exportData = allPlans
    .map((plan) => {
      return {
        date_sent: plan.disbursementPlanStartDate,
        date_accepted: "TODO",
        disbursement_name: plan.disbursementPlanName,
        disbursement_id: plan.disbursementPlanId,
        transfer_id: plan.idString,
        paypal_id: "TODO",
        payee_id: plan.payeeId.toString(),
        payee_name: plan.payeeName,
        payee_email: plan.payeeEmail,
        transfer_status: plan.status,
        fail_reason: "TODO",
        accepted_method: "TODO",
        amount: plan.amount,
      };
    })
    .filter(
      ({ date_sent }) =>
        date_sent >= reportsStartDate?.toISOString()?.split("T")[0] &&
        date_sent <= reportsEndDate?.toISOString()?.split("T")[0]
    );

  sortData(exportData);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const csvExport = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + ".csv");
  };

  return (
    <div className="page">
      <h2>Reports</h2>
      <div
        style={{
          marginTop: 10,
          fontSize: 16,
          fontWeight: 300,
        }}
      >
        Analyze data
      </div>
      <hr></hr>
      <div style={{ margin: "10px 0 30px", color: "hotpink" }}>
        <span>TODO </span>
        <span>
          start and end date filtering should probably happen in the backend at
          some point
        </span>
      </div>
      {dateSelector()}
      {exportData.length > 0 ? (
        <>
          <button
            style={{
              backgroundColor: theme.accent_color,
              borderColor: theme.accent_color,
              color: theme.bg_tertiary,
              margin: "40px 0",
            }}
            onClick={() =>
              csvExport(
                exportData,
                `disbursements_report_${reportsStartDate?.toISOString()?.split("T")[0]}_thru_${reportsEndDate?.toISOString()?.split("T")[0]}`
              )
            }
          >
            Export CSV
          </button>
          <table>
            <thead
              style={{
                height: 42,
              }}
            >
              <tr style={{ height: 64 }}>
                <td colSpan={13} style={{ fontSize: 18, fontWeight: 550 }}>
                  Transfer History
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  backgroundColor: theme.bg_tertiary,
                  fontSize: 12,
                  height: 42,
                }}
              >
                {sortableHeader("date_sent", "Date sent")}
                {sortableHeader("date_accepted", "Date accepted")}
                {sortableHeader("disbursement_name", "Disbursement name")}
                {sortableHeader("disbursement_id", "Disbursement id")}
                {sortableHeader("transfer_id", "Transfer id")}
                {sortableHeader("paypal_id", "Paypal id")}
                {sortableHeader("payee_id", "Payee id")}
                {sortableHeader("payee_name", "Payee name")}
                {sortableHeader("payee_email", "Payee email")}
                {sortableHeader("transfer_status", "Transfer status")}
                {sortableHeader("fail_reason", "Fail reason")}
                {sortableHeader("accepted_method", "Accepted method")}
                {sortableHeader("amount", "Amount")}
              </tr>
              {exportData.map((plan, i) => {
                return (
                  <tr key={i} className="border_top">
                    <td>{plan.date_sent}</td>
                    <td>{plan.date_accepted}</td>
                    <td>{plan.disbursement_name}</td>
                    <td>{plan.disbursement_id}</td>
                    <td>{plan.transfer_id}</td>
                    <td>{plan.paypal_id}</td>
                    <td>{plan.payee_id}</td>
                    <td>{plan.payee_name}</td>
                    <td>{plan.payee_email}</td>
                    <td>{plan.transfer_status} </td>
                    <td>{plan.fail_reason}</td>
                    <td>{plan.accepted_method}</td>
                    <td>{plan.amount && displayDollarAmount(plan.amount)}</td>
                  </tr>
                );
              })}
              {/* remove below for v0 */}
              {/* <tr className="border_top">
                <td colSpan={3}>
                  <span>TODO </span>
                  <span>pagination</span>
                </td>
                <td colSpan={10} style={{ fontWeight: 900 }}>
                  <div style={{ display: "flex", float: "right" }}>
                    <button
                      style={{
                        backgroundColor: theme.bg_primary,
                        borderColor: theme.text_secondary,
                        fontWeight: 550,
                        marginRight: 10,
                      }}
                      onClick={() => console.log("Previous")}
                    >
                      Previous
                    </button>
                    <button
                      style={{
                        backgroundColor: theme.bg_primary,
                        borderColor: theme.text_secondary,
                        fontWeight: 550,
                      }}
                      onClick={() => console.log("Next")}
                    >
                      Next
                    </button>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </>
      ) : (
        <div style={{ marginTop: 25 }}>
          {renderErrorOrLoadingMessage("Report")}
        </div>
      )}
    </div>
  );
};
export default Reports;
