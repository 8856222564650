import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ROUTE_CONSTANTS } from "./constants/routeConstants";
import {
  Dashboard,
  DisbursementsForm,
  DisbursementDetail,
  Login,
  Monitor,
  PayeesScreen,
  Reports,
  Settings,
  Sidebar,
} from "./screens";
import Theme from "./theme/theme";
import { useAuth0 } from "@auth0/auth0-react";
import useAPI from "./api/api";
import { datadogRum } from "@datadog/browser-rum";

const AppRouter = () => {
  const {
    DASHBOARD,
    DISBURSEMENT_DETAIL,
    DISBURSEMENTS_FORM,
    MONITOR,
    PAYEES,
    REPORTS,
    SETTINGS,
  } = ROUTE_CONSTANTS;
  const { isAuthenticated, getIdTokenClaims, logout } = useAuth0();
  const { theme, dropDownStyle, dropDownTheme } = Theme();
  const location = useLocation();
  const [token, setToken] = useState("");
  const api = useAPI(token);
  const [isVerified, setIsVerified] = useState(false);
  const [cookieVerified, setCookieVerified] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [viewTiles, setViewTiles] = useState(true);
  const [filterDisbursements, setFilterDisbursements] = useState(null);
  const [filterDashboard, setFilterDashboard] = useState(null);
  const [filterPayeePlans, setFilterPayeePlans] = useState(null);

  const [sortDisbursementsConfig, setSortDisbursementsConfig] = useState({
    field: "createdAtLocalTimezone",
    ascending: false,
  });
  const [sortDisbursementDetailConfig, setSortDisbursementDetailConfig] =
    useState({
      field: "payeeName",
      ascending: true,
    });
  const [sortPayeesConfig, setSortPayeesConfig] = useState({
    field: "name",
    ascending: true,
  });
  const [sortPayeePlansConfig, setSortPayeePlansConfig] = useState({
    field: "disbursementPlanStartDate",
    ascending: false,
  });
  const [sortReportPlansConfig, setSortReportPlansConfig] = useState({
    field: "date_sent",
    ascending: false,
  });

  let oneWeekAgo = new Date();
  oneWeekAgo.setMonth(oneWeekAgo.getMonth() - 1);
  const today = new Date();

  const [dashboardStartDate, setDashboardStartDate] = useState(undefined);
  const [dashboardEndDate, setDashboardEndDate] = useState(undefined);

  const [reportsStartDate, setReportsStartDate] = useState(oneWeekAgo);
  const [reportsEndDate, setReportsEndDate] = useState(today);

  useEffect(() => {
    const getToken = async () => {
      try {
        const tokenClaims = await getIdTokenClaims();
        setToken(tokenClaims.__raw);
      } catch (error) {
        setAuthError(true);
        console.error("get token error", error);
      }
    };
    if (isAuthenticated) {
      getToken();
    }
  }, [getIdTokenClaims, isAuthenticated]);

  useEffect(() => {
    const Verify = async () => {
      try {
        await api.get(`/verify`);
        setIsVerified(true);
      } catch (error) {
        setAuthError(true);
        console.error("verify error", error);
      }
    };
    if (token) {
      Verify();
    }
  }, [api, token]);

  useEffect(() => {
    const VerifyCookie = async () => {
      try {
        await api.get(`/verify-cookie`);
        setCookieVerified(true);
      } catch (error) {
        setAuthError(true);
        console.error("verify cookie error", error);
      }
    };
    if (isVerified) {
      VerifyCookie();
    }
  }, [api, isVerified]);

  const highlightToDos = () => {
    // setTimeout(() => {
    //   document.querySelectorAll("*").forEach((element) => {
    //     if (element.textContent.trim() === "TODO") {
    //       element.style.color = "hotpink";
    //     }
    //   });
    // }, 500);
  };

  useEffect(() => {
    highlightToDos();
  }, [
    location.pathname,
    viewTiles,
    filterDisbursements,
    // reportsStartDate,
    // reportsEndDate,
  ]);

  if (isAuthenticated && !window.location.host.includes("localhost")) {
    datadogRum.startSessionReplayRecording();
  }

  return (
    <>
      {cookieVerified ? (
        <div style={{ display: "flex" }}>
          <Sidebar />
          <Routes>
            <Route
              path={DASHBOARD}
              element={
                <Dashboard
                  theme={theme}
                  filterDashboard={filterDashboard}
                  setFilterDashboard={setFilterDashboard}
                  dashboardStartDate={dashboardStartDate}
                  setDashboardStartDate={setDashboardStartDate}
                  dashboardEndDate={dashboardEndDate}
                  setDashboardEndDate={setDashboardEndDate}
                />
              }
            />
            <Route
              path={DISBURSEMENTS_FORM}
              element={
                <DisbursementsForm
                  theme={theme}
                  dropDownStyle={dropDownStyle}
                  dropDownTheme={dropDownTheme}
                />
              }
            />
            <Route
              path={DISBURSEMENT_DETAIL}
              element={
                <DisbursementDetail
                  theme={theme}
                  sortDisbursementDetailConfig={sortDisbursementDetailConfig}
                  setSortDisbursementDetailConfig={
                    setSortDisbursementDetailConfig
                  }
                  highlightToDos={highlightToDos}
                />
              }
            />
            <Route
              path={MONITOR}
              element={
                <Monitor
                  theme={theme}
                  viewTiles={viewTiles}
                  setViewTiles={setViewTiles}
                  filterDisbursements={filterDisbursements}
                  setFilterDisbursements={setFilterDisbursements}
                  sortDisbursementsConfig={sortDisbursementsConfig}
                  setSortDisbursementsConfig={setSortDisbursementsConfig}
                />
              }
            />
            <Route
              path={PAYEES}
              element={
                <PayeesScreen
                  theme={theme}
                  sortPayeesConfig={sortPayeesConfig}
                  setSortPayeesConfig={setSortPayeesConfig}
                  sortPayeePlansConfig={sortPayeePlansConfig}
                  setSortPayeePlansConfig={setSortPayeePlansConfig}
                  filterPayeePlans={filterPayeePlans}
                  setFilterPayeePlans={setFilterPayeePlans}
                  highlightToDos={highlightToDos}
                />
              }
            />
            <Route
              path={REPORTS}
              element={
                <Reports
                  theme={theme}
                  sortReportPlansConfig={sortReportPlansConfig}
                  setSortReportPlansConfig={setSortReportPlansConfig}
                  reportsStartDate={reportsStartDate}
                  setReportsStartDate={setReportsStartDate}
                  reportsEndDate={reportsEndDate}
                  setReportsEndDate={setReportsEndDate}
                />
              }
            />
            <Route path={SETTINGS} element={<Settings theme={theme} />} />
            <Route path="/" element={<Navigate to={MONITOR} />} />
            <Route
              path="/*"
              element={
                <div className="center_page">
                  <h3>Page not found</h3>
                </div>
              }
            />
          </Routes>
        </div>
      ) : (
        <Routes>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <div className="center_page" style={{ textAlign: "center" }}>
                  {authError ? (
                    <div>
                      Something went wrong while loading your account.
                      <div className="center_div" style={{ marginTop: 20 }}>
                        <button
                          onClick={() =>
                            logout({
                              logoutParams: {
                                returnTo: window.location.origin,
                              },
                            })
                          }
                        >
                          Try again
                        </button>
                      </div>
                    </div>
                  ) : (
                    <h3>Loading account data</h3>
                  )}
                </div>
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      )}
    </>
  );
};

export default AppRouter;
