import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import useAPI from "../api/api";
import { unCapitalize } from "../utils/utils";
import { ReactComponent as Calendar } from "../assets/ico_calendar.svg";
import { ReactComponent as Page } from "../assets/ico_page.svg";
import { ReactComponent as Check } from "../assets/ico_check.svg";
import { ReactComponent as Loading } from "../assets/ico_loading.svg";
import { ReactComponent as Cancel } from "../assets/ico_cancel_circle.svg";
import { ReactComponent as People } from "../assets/ico_people.svg";
import { ReactComponent as Monitor } from "../assets/ico_monitor.svg";
import DataLoadingAndErrorHandling from "../components/dataLoadingAndErrorHandling";
import { useAuth0 } from "@auth0/auth0-react";
import DateSelector from "../components/dateSelector";

const Dashboard = ({
  theme,
  dashboardStartDate,
  setDashboardStartDate,
  dashboardEndDate,
  setDashboardEndDate,
}) => {
  const { user } = useAuth0();
  const api = useAPI();
  const navigate = useNavigate();
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [allDisbursements, setAllDisbursements] = useState([]);
  const [disbursementsToRender, setDisbursementsToRender] = useState([]);
  const [plansToRender, setPlansToRender] = useState([]);

  const { MONITOR, PAYEES } = ROUTE_CONSTANTS;

  const { dateSelector } = DateSelector(
    theme,
    setShowDateSelector,
    dashboardStartDate,
    setDashboardStartDate,
    dashboardEndDate,
    setDashboardEndDate
  );

  const { renderErrorOrLoadingMessage, setFetchLoading, setFetchError } =
    DataLoadingAndErrorHandling();

  useEffect(() => {
    const plans = [];
    const getDisbursementPlans = async () => {
      try {
        setFetchLoading(true);
        const response = await api.get(`/disbursement_plans`);
        response.data.disbursementPlans.forEach((disbursementPlan) => {
          disbursementPlan.payeePlans.forEach((plan) => {
            plan.startDate = disbursementPlan.startDate;
            if (plan.status) {
              plan.status = unCapitalize(plan.status);
            }
            plans.push(plan);
          });
        });
        setAllDisbursements(response.data.disbursementPlans);
        setAllPlans(plans);
      } catch (error) {
        setFetchError(true);
        console.error("get disbursement plans error", error);
      } finally {
        setFetchLoading(false);
      }
    };
    getDisbursementPlans();
  }, [api, setFetchLoading, setFetchError]);

  const payeeCount = [
    ...new Set(plansToRender.map(({ payeeEmail }) => payeeEmail)),
  ].length;

  useEffect(() => {
    if (dashboardStartDate && dashboardEndDate) {
      setPlansToRender(
        allPlans
          .filter(
            ({ startDate }) =>
              startDate >= dashboardStartDate?.toISOString()?.split("T")[0]
          )
          .filter(
            ({ startDate }) =>
              startDate <= dashboardEndDate?.toISOString()?.split("T")[0]
          )
      );
      setDisbursementsToRender(
        allDisbursements
          .filter(
            ({ startDate }) =>
              startDate >= dashboardStartDate?.toISOString()?.split("T")[0]
          )
          .filter(
            ({ startDate }) =>
              startDate <= dashboardEndDate?.toISOString()?.split("T")[0]
          )
      );
    } else if (dashboardStartDate && !dashboardEndDate) {
      setPlansToRender(
        allPlans.filter(
          ({ startDate }) =>
            startDate >= dashboardStartDate?.toISOString()?.split("T")[0]
        )
      );
      setDisbursementsToRender(
        allDisbursements.filter(
          ({ startDate }) =>
            startDate >= dashboardStartDate?.toISOString()?.split("T")[0]
        )
      );
    } else if (!dashboardStartDate && dashboardEndDate) {
      setPlansToRender(
        allPlans.filter(
          ({ startDate }) =>
            startDate <= dashboardEndDate?.toISOString()?.split("T")[0]
        )
      );
      setDisbursementsToRender(
        allDisbursements.filter(
          ({ startDate }) =>
            startDate <= dashboardEndDate?.toISOString()?.split("T")[0]
        )
      );
    } else if (!dashboardStartDate && !dashboardEndDate) {
      setPlansToRender(allPlans);
      setDisbursementsToRender(allDisbursements);
    }
  }, [dashboardStartDate, dashboardEndDate, allPlans, allDisbursements]);

  const statusTile = (tileStatus, title, icon, color) => {
    return (
      <div
        className="tile"
        // onClick={() => setHandleFilterBy(tileStatus ? tileStatus : null)}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="status_icon"
            style={{
              background: color,
            }}
          >
            {icon}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              lineHeight: 0.4,
              fontWeight: 600,
              opacity: 0.5,
            }}
          >
            <div>.</div>
            <div>.</div>
            <div>.</div>
          </div>
        </div>
        <div style={{ margin: "15px 0" }}>{title}</div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* number of transfers */}
          <div style={{ fontSize: 32, fontWeight: 600 }}>
            {tileStatus
              ? plansToRender.filter(({ status }) => status === tileStatus)
                  ?.length
              : plansToRender.length}
          </div>
          {/* total amount */}
          <div
            className="filter_by_box"
            style={{
              height: "fit-content",
              backgroundColor: theme.bg_tertiary,
            }}
          >
            ${" "}
            {plansToRender
              .filter(({ status }) => status === tileStatus)
              .reduce((runningTotal, recipient) => {
                if (recipient.amount) {
                  return runningTotal + parseFloat(recipient.amount);
                } else {
                  return runningTotal;
                }
              }, 0)
              .toFixed(2)}
          </div>
        </div>
      </div>
    );
  };

  const navTile = (navTo, icon, count, title, subtitle) => {
    return (
      <div className="tile hoverable" onClick={() => navigate(navTo)}>
        <div
          className="outline_icon"
          style={{ backgroundColor: "transparent" }}
        >
          {icon}
        </div>
        {title} <h2> {count}</h2>
        <hr
          style={{
            margin: "20px -20px",
            borderColor: theme.bg_tertiary,
            borderWidth: 1,
          }}
        ></hr>
        <div
          style={{
            textAlign: "end",
            fontSize: 14,
            color: theme.accent_color,
          }}
        >
          {subtitle}
        </div>
      </div>
    );
  };

  return (
    <div className="page">
      {showDateSelector && (
        <>
          <div className="overlay"></div>
          <div className="modal vertical_space_between">{dateSelector()}</div>
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <h2>Welcome back, {user.name}</h2>
          <div
            style={{
              marginTop: 10,
              fontSize: 16,
              fontWeight: 300,
            }}
          >
            {user.email}
          </div>
        </div>
        <button
          className="hoverable bg_primary_button"
          style={{
            height: 36,
            marginTop: 12,
          }}
          onClick={() => setShowDateSelector(true)}
        >
          <Calendar
            className="fill_bg_primary stroke_text_primary"
            style={{
              transform: "scale(.8)",
              margin: "-5 5 0 0",
              backgroundColor: "transparent",
            }}
          />
          <span style={{ whiteSpace: "nowrap" }}>Select Dates</span>
        </button>
      </div>

      {plansToRender?.length > 0 ? (
        <>
          <div className="status_tiles_container">
            {statusTile(
              null,
              "Total transfers",
              <Page style={{ stroke: theme.accent_color }} />,
              theme.accent_color_light
            )}
            {statusTile(
              "DRAFT",
              "Successful transfers",
              <Check style={{ fill: theme.success_color }} />,
              theme.success_color_light
            )}
            {statusTile(
              "APPROVED",
              "Pending transfers",
              <Loading style={{ fill: theme.info_color }} />,
              theme.info_color_light
            )}
            {statusTile(
              "IN_PROCESS",
              "Failed transfers",
              <Cancel style={{ fill: theme.alert_color }} />,
              theme.alert_color_light
            )}
          </div>

          <div className="tile status_tiles_container">
            {navTile(
              MONITOR,
              <Monitor className="stroke_text_primary" />,
              disbursementsToRender.length,
              "Total Disbursements",
              "Monitor"
            )}
            {navTile(PAYEES, <People />, payeeCount, "Total Payees", "Payees")}
          </div>
        </>
      ) : (
        <>{renderErrorOrLoadingMessage("Transfers")}</>
      )}
    </div>
  );
};
export default Dashboard;
